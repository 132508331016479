.p-dropdown{
    box-shadow: none;
}
@media screen and (max-width:360px ) {
    
    .p-dropdown-panel{
        left: 20px !important;
        right: 24px;
        min-width: unset !important ;
    }
}
/* @media screen and (min-width:600px) {
    
    .p-dropdown-panel{
        left: 0px !important;
        right: unset;
        
    }
} */