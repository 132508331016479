/* .slick-track{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
} */

/* .slick-list{
    padding: 0 !important;
    height: fit-content !important; */
    /* height: 400px !important; */
    /* width: 400px !important;
} */
/* .slick-slide.slick-active{
    width: 100% !important;
} */

/* .slick-slider.slick-vertical{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.slick-prev,.slick-next{
    width: 96px;
    height: 30px;
    position: static;
}
.slick-next:before{
    content: none;
} */

.p-galleria-thumbnail-container{
    background-color: inherit;
    height: 484px ;
    position: relative;
}
.p-galleria-thumbnail-prev,.p-galleria-thumbnail-next{
    color: #000;
    position: absolute;
    width: 96px;
    background-color: #f8f9ff;
}
.p-galleria-thumbnail-prev{
    z-index: 1;
}
.p-galleria-thumbnail-next{
    bottom: 4px;
}
.p-galleria-thumbnail-next.p-disabled,.p-galleria-thumbnail-prev.p-disabled{
    opacity: 0;
}
.p-galleria-thumbnail-items-container{
    height: 100% !important;
    border: 1px solid #f8f9ff;
}
.p-galleria-thumbnail-item-current img{
    border: 1px solid #27a1ff;
}
[data-pc-section="thumbnailitem"]{
    flex: none !important;
    margin-bottom: 20px;
}
.p-galleria-indicator .p-link{
    border-radius: 50%;
}
.p-inputtext:enabled:focus{
    border-color: #CBD5E1 !important ;
    box-shadow: none !important;
}
.p-inputtext:enabled:focus{
    border-color: #CBD5E1 !important ;
    box-shadow: none !important;
}
.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext{
    border-color: #CBD5E1 !important ;
    box-shadow: none !important;
}
.p-inputtext:enabled:hover{
    border-color:  #CBD5E1 !important;
}
.p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext{
    border-color:  #CBD5E1 !important;


}